import { PreloaderIcon } from "@/assets/icons/PreloaderIcon";
import Box from "@mui/material/Box/Box";
import { AppTypography } from "@/components/AppTypography";

interface Props {
  color?: string;
  text?: string;
}

export const AppLoader = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        color: props.color || "#FFFFFF",
      }}
    >
      <AppTypography opacity={0.8}>{props.text}</AppTypography>
      <PreloaderIcon />
    </Box>
  );
};
