import { api } from "@/app/api/core";
import { IS_DEV } from "@/common/constants";
import { getStrapiUrl } from "@/common/utils/getStrapiUrl";
import { LibraryItemType } from "@/modules/library/types";
import { AxiosResponse } from "axios";

export const articlesApi = {
  async getArticles(params?: { search?: string }) {
    const search = params?.search;
    const formattedSearch = params?.search
      ? params?.search?.charAt(0).toUpperCase() + params?.search?.toLowerCase().slice(1)
      : "";

    const articlesQuery = {
      populate: "image, authors",
      sort: ["createdAt:desc"],
      filters: {
        $or: [
          {
            authors: {
              fullName: {
                $containsi: search,
              },
            },
          },
          {
            authors: {
              fullName: {
                $containsi: formattedSearch,
              },
            },
          },
          {
            title: {
              $containsi: search,
            },
          },
          {
            title: {
              $containsi: formattedSearch,
            },
          },
        ],
      },
    };

    try {
      const res = await api.get<AxiosResponse<LibraryItemType[]>>(getStrapiUrl("articles", articlesQuery));
      if (IS_DEV) console.log("articles", res);

      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  async getArticleById(params: { id: LibraryItemType["id"] }) {
    try {
      const res = await api.get<AxiosResponse<LibraryItemType[]>>(
        getStrapiUrl("articles", {
          populate: "image, authors, file",
          filters: { id: { $eq: params.id } },
        }),
      );
      if (IS_DEV) console.log("article", res);
      return res.data.data[0];
    } catch (err) {
      console.log(err);
    }
  },
  async updateArticleById(payload: Partial<LibraryItemType["attributes"] & { id: number }>) {
    try {
      const body = {
        data: { ...payload },
      };
      const res = await api.put(getStrapiUrl(`articles/${payload.id}`), body);
      return res.data.data[0];
    } catch (err) {
      console.log(err);
    }
  },
};
